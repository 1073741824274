import {
  ContactDetails,
  LandingPage,
  MedicalDashboard,
  MedicalLanding,
  MedicalLandingPage,
  MedicalNothingToDeclare,
  QuestionEight,
  QuestionFive,
  QuestionFour,
  QuestionMedicalConditions,
  QuestionMedicalConfirmation,
  QuestionMedicalTreatments,
  QuestionOne,
  QuestionSeven,
  QuestionSix,
  QuestionThree,
  QuestionTwo,
  QuoteDecline,
  QuoteReview,
  Review,
} from "@/config/app-constant";

export type PageName =
  | typeof LandingPage
  | typeof QuestionOne
  | typeof QuestionTwo
  | typeof QuestionThree
  | typeof QuestionFour
  | typeof QuestionFive
  | typeof QuestionSix
  | typeof QuestionSeven
  | typeof QuestionEight
  | typeof MedicalLandingPage
  | typeof MedicalLanding
  | typeof QuestionMedicalConfirmation
  | typeof QuestionMedicalConditions
  | typeof QuestionMedicalTreatments
  | typeof MedicalDashboard
  | typeof MedicalNothingToDeclare
  | typeof ContactDetails
  | typeof QuoteDecline
  | typeof Review
  | typeof QuoteReview;

interface Pages {
  name: PageName;
  prev: PageName | null;
  next: PageName | null;
  progress: number;
}

const pages: Pages[] = [
  {
    name: LandingPage,
    prev: null,
    next: QuestionOne,
    progress: -1,
  },
  {
    name: QuestionOne,
    prev: null,
    next: QuestionTwo,
    progress: -1,
  },
  {
    name: QuestionTwo,
    prev: QuestionOne,
    next: QuestionThree,
    progress: 7,
  },
  {
    name: QuestionThree,
    prev: QuestionTwo,
    next: QuestionFour,
    progress: 14,
  },
  {
    name: QuestionFour,
    prev: QuestionThree,
    next: QuestionFive,
    progress: 21,
  },
  {
    name: QuestionFive,
    prev: QuestionFour,
    next: QuestionSix,
    progress: 28,
  },
  {
    name: QuestionSix,
    prev: QuestionFive,
    next: QuestionSeven,
    progress: 35,
  },
  {
    name: QuestionSeven,
    prev: QuestionSix,
    next: QuestionEight,
    progress: 42,
  },
  {
    name: QuestionEight,
    prev: QuestionSeven,
    next: MedicalLandingPage,
    progress: 56,
  },
  {
    name: MedicalLandingPage,
    prev: QuestionSeven,
    next: QuestionMedicalConfirmation,
    progress: 63,
  },
  {
    name: MedicalLanding,
    prev: QuestionEight,
    next: QuestionMedicalConfirmation,
    progress: 63,
  },
  {
    name: QuestionMedicalConfirmation,
    prev: MedicalLandingPage,
    next: QuestionMedicalConditions,
    progress: 70,
  },
  {
    name: QuestionMedicalConditions,
    prev: QuestionMedicalConfirmation,
    next: QuestionMedicalTreatments,
    progress: 77,
  },
  {
    name: QuestionMedicalTreatments,
    prev: QuestionMedicalConditions,
    next: MedicalDashboard,
    progress: 84,
  },
  {
    name: MedicalDashboard,
    prev: QuestionMedicalTreatments,
    next: ContactDetails,
    progress: 91,
  },
  {
    name: MedicalNothingToDeclare,
    prev: null,
    next: ContactDetails,
    progress: 91,
  },
  {
    name: ContactDetails,
    prev: MedicalDashboard,
    next: null,
    progress: 98,
  },
  {
    name: QuoteDecline,
    prev: null,
    next: null,
    progress: 100,
  },
  {
    name: Review,
    prev: null,
    next: QuoteReview,
    progress: -1,
  },
];

export default pages;

// Helper function to get the previous page for a given page
export const getRouterPathBackButton = (currentPage: PageName) => {
  const currentPageInfo = pages.find((page) => page.name === currentPage);
  return currentPageInfo?.prev ? `/${currentPageInfo.prev}` : `/`;
};

// Helper function to get the next page for a given page
export const getRouterPathMainButton = (currentPage: PageName) => {
  const currentPageInfo = pages.find((page) => page.name === currentPage);
  return currentPageInfo?.next ? `/${currentPageInfo.next}` : `/`;
};
