"use client";

import { useEffect, useState } from "react";

export const getMatches = (query: string): boolean => {
  // Prevents SSR issues
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }
  return false;
};

export default function useMediaQuery(queryValue: string): [boolean, boolean] {
  const [matches, setMatches] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    const matchMedia =
      typeof window !== "undefined" && window.matchMedia(queryValue);

    function handleChange() {
      setMatches(getMatches(queryValue));
      if (!isReady) setIsReady(true);
    }

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia && matchMedia.addEventListener) {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia && matchMedia.removeEventListener) {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
  }, [queryValue, isReady]);

  return [matches === true, isReady];
}
