/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  PhoneIcon,
  MessageIcon,
  RightArrowIcon,
} from "staysure-component-library";

import LabelButtonWrapper, {
  ErrorButtonWrapper,
} from "@/components/atoms/button-wrapper/button-wrapper";
import {
  ErrorButtonWrapperProps,
  LabelButtonWrapperProps,
} from "@/types/commonTypes";

export function Call(props: LabelButtonWrapperProps) {
  const { label } = props;
  const handleCall = (number: string) => {
    const numberToCall = /\d+/.exec(number.split(" ").join(""));
    const telUri = `tel:${numberToCall?.[0]}`;
    return telUri;
  };
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <a className="callus__tel_no" href={`${handleCall(label)}`} tabIndex={-1}>
      <LabelButtonWrapper {...props} isLeftIcon leftIcon={<PhoneIcon />} />
    </a>
  );
}

export function Agent(props: LabelButtonWrapperProps) {
  return (
    <LabelButtonWrapper {...props} isLeftIcon leftIcon={<MessageIcon />} />
  );
}

export function ChatBot(props: LabelButtonWrapperProps) {
  const handleChatBot = () => {
    // handle ChatBot
  };
  return (
    <LabelButtonWrapper
      {...props}
      isLeftIcon
      leftIcon={<MessageIcon />}
      onClick={() => handleChatBot()}
    />
  );
}

export function AddButton(props: LabelButtonWrapperProps) {
  return (
    <LabelButtonWrapper
      {...props}
      isLeftIcon
      leftIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.002 5V19M5.00195 12H19.002"
            stroke="#306582"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    />
  );
}

export function TextButton(props: LabelButtonWrapperProps) {
  return <LabelButtonWrapper {...props} />;
}

export function RemoveButton(props: ErrorButtonWrapperProps) {
  return <ErrorButtonWrapper {...props} className="text-text-critical" />;
}

export function LoadMore(props: LabelButtonWrapperProps) {
  return (
    <LabelButtonWrapper
      {...props}
      // eslint-disable-next-line react/destructuring-assignment
      isRightIcon={props.isRightIcon ?? true}
      rightIcon={<RightArrowIcon />}
    />
  );
}

export function ExpatButton(props: LabelButtonWrapperProps) {
  return (
    <LabelButtonWrapper
      {...props}
      isRightIcon
      rightIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="External link">
            <path
              id="Union"
              d="M14.252 3C14.252 2.58579 14.5877 2.25 15.002 2.25H21.002C21.4162 2.25 21.752 2.58579 21.752 3V9C21.752 9.41421 21.4162 9.75 21.002 9.75C20.5877 9.75 20.252 9.41421 20.252 9V4.81066L10.5323 14.5303C10.2394 14.8232 9.76452 14.8232 9.47162 14.5303C9.17873 14.2374 9.17873 13.7626 9.47162 13.4697L19.1913 3.75H15.002C14.5877 3.75 14.252 3.41421 14.252 3ZM5.00195 6.75C4.67043 6.75 4.35249 6.8817 4.11807 7.11612C3.88365 7.35054 3.75195 7.66848 3.75195 8V19C3.75195 19.3315 3.88365 19.6495 4.11807 19.8839C4.35249 20.1183 4.67043 20.25 5.00195 20.25H16.002C16.3335 20.25 16.6514 20.1183 16.8858 19.8839C17.1203 19.6495 17.252 19.3315 17.252 19V13C17.252 12.5858 17.5877 12.25 18.002 12.25C18.4162 12.25 18.752 12.5858 18.752 13V19C18.752 19.7293 18.4622 20.4288 17.9465 20.9445C17.4308 21.4603 16.7313 21.75 16.002 21.75H5.00195C4.27261 21.75 3.57313 21.4603 3.05741 20.9445C2.54168 20.4288 2.25195 19.7293 2.25195 19V8C2.25195 7.27065 2.54168 6.57118 3.05741 6.05546C3.57313 5.53973 4.27261 5.25 5.00195 5.25H11.002C11.4162 5.25 11.752 5.58579 11.752 6C11.752 6.41421 11.4162 6.75 11.002 6.75H5.00195Z"
              fill="#272427"
            />
          </g>
        </svg>
      }
    />
  );
}
